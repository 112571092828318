import React from 'react';
import { Layout } from '../../components';

const LegalPage = () => (
  <Layout pageTitle="Legal">
    <section className="legal-section text-center bg-dark">
      <div className="container">
        <div className="row">
          <div>
            <h1 className="text-white">Legal Information</h1>
            <div className="text-left text-white-60 col-lg-10 mx-auto">
              <p>
                Cobalt Hills Tech is a limited liability company for computer
                activities and services, Pod vrh 7, Cernik, 51219 Čavle,
                Croatia.
              </p>
              <p>
                The company is registered with the Commercial Court in Rijeka
                under MBS number 040442346 and EUID number HRSR.040442346.
                Company's PIN number: 25966171359
              </p>
              <p>
                Bank Account No. HR3824020061101103186 with
                Erste&Steiermärkische Bank d.d., Jadranski trg 3A, 51000 Rijeka,
                Croatia. BIC / SWIFT number: ESBCHR22
              </p>
              <p>
                Share capital in the amount of 20,000.00 HRK paid in full.
                <br />
                Person authorised to represent: Leo Brdar
              </p>

              <h3 className="text-white">General Terms & Conditions</h3>
              <p>
                We provide our services in both business-to-business (B2B) and
                business-to-consumer (B2C) models.
              </p>
              <p>
                If you're a business user / company, find more information about
                how we provide our services in our&nbsp;
                <a className="text-white-60" href="/legal/b2b-terms-conditions">
                  B2B General Terms and Conditions
                </a>
                .
              </p>
              <p>
                If you're a private user / consumer, find more information about
                how we provide our services in our&nbsp;
                <a className="text-white-60" href="/legal/b2c-terms-conditions">
                  B2C General Terms and Conditions
                </a>
                .
              </p>

              <h3 className="text-white">Privacy and Cookie Policy</h3>
              <p>
                Your privacy is important to us. For more information on how we
                handle your data and cookies read our&nbsp;
                <a className="text-white-60" href="/legal/privacy-policy">
                  Privacy and Cookie Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default LegalPage;
